import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import FocusIcon from '@mui/icons-material/CenterFocusStrong';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import WarningsMetric from "./metrics/WarningsMetric";
import WorkItem from "../model/WorkItem";
import WorkItemIcon from "./WorkItemIcon";
import WorkItemType from "../model/WorkItemType";
import WorkItemState from "../model/WorkItemState";

type WorkItemCardHeaderProps = {
  workItem: WorkItem;
  allNodes: WorkItem[];
  leafNodes: WorkItem[];
  onFocus?: (event: React.SyntheticEvent<Element, Event>, id: number) => void
};

export default function WorkItemCardHeader({ workItem, allNodes, leafNodes, onFocus }: WorkItemCardHeaderProps) {
  return (
    <CardHeader
      // action={<IconButton aria-label="settings"><MoreVertIcon /></IconButton>}
      title={
        <Box>
          <Box display="flex" width="100%" alignItems="center">
            <WorkItemIcon type={workItem.type} sx={{ marginRight: 2, transform: "scale(1.5)" }} />
            <Typography fontWeight="bold">{WorkItemType.toString(workItem.type)} {workItem.id}</Typography>
            <Box marginLeft='auto'>
              <WarningsMetric workItem={workItem} allNodes={allNodes} leafNodes={leafNodes} />
            </Box>
          </Box>
          <Box paddingTop={2} paddingBottom={2}>
            {workItem.title}
            {
              workItem.childIds.length > 0 && onFocus &&
              <IconButton sx={{ marginLeft: 1 }} onClick={(e) => onFocus(e, workItem.id)}>
                <FocusIcon />
              </IconButton>
            }
          </Box>
        </Box>
      }
      subheader={
        <Box>
          <Box display="flex">
            <Box>Assigned to: {workItem?.serviceDocument?.fields?.['System.AssignedTo']?.displayName}</Box>
            <Box marginLeft="auto">
              Target: {workItem.iterationAsNumber === WorkItem.FUTURE_ITERATION ? "Future" : workItem.iterationAsNumber}
            </Box>
          </Box>
          <Box display="flex">
            <Box>State: {WorkItemState.toString(workItem.state)}</Box>
            <Box marginLeft="auto">
              Schedule: {workItem.getScheduledIterationsAsFormattedString(allNodes)}
            </Box>
          </Box>
        </Box>
      }
    />
  );
}