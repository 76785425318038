import { useEffect, useState } from "react";
import WorkItem from "../../model/WorkItem";
import WorkItemMetric from "./WorkItemMetric";

type ScheduledCompletedMetricProps = {
  workItem: WorkItem;
  leafNodes: WorkItem[];
};

export default function ScheduledCompletedMetric({ workItem, leafNodes }: ScheduledCompletedMetricProps) {
  const [scheduledRemainingDays, setScheduledRemainingDays] = useState(0);
  const [scheduledOriginalEstimate, setScheduledOriginalEstimate] = useState(0);
  const [scheduledCompletedDays, setScheduledCompletedDays] = useState(0);
  const [scheduledCompletedPercent, setScheduledCompletedPercent] = useState(0);

  // Calculate remainingDays
  useEffect(() => {
    const total = leafNodes.reduce((total: number, item: WorkItem) => {
      if (!item.isScheduled)
        return total;

      return total + (item.remainingDays ?? item.originalEstimate ?? 0);
    }, 0);
    setScheduledRemainingDays(total);
  }, [leafNodes]);

  // Calculate originalEstimate
  useEffect(() => {
    const total = leafNodes.reduce((total: number, item: WorkItem) => {
      if (!item.isScheduled)
        return total;

      let estimate = (item.originalEstimate ?? 0);
      let remaining = (item.remainingDays ?? 0);
      estimate = remaining > estimate ? remaining : estimate;
      return total + estimate;
    }, 0);
    setScheduledOriginalEstimate(total);
  }, [leafNodes]);

  // Caclulate completedDays
  useEffect(() => {
    setScheduledCompletedDays(scheduledOriginalEstimate - scheduledRemainingDays);
  }, [scheduledOriginalEstimate, scheduledRemainingDays]);

  // Caclulate percentComplete
  useEffect(() => {
    setScheduledCompletedPercent(scheduledOriginalEstimate ? (100 * scheduledCompletedDays / scheduledOriginalEstimate) : 0);
  }, [scheduledOriginalEstimate, scheduledCompletedDays]);

  return (
    <WorkItemMetric
      percentage={parseInt(Number(scheduledCompletedPercent).toFixed())}
      state={<>FOR WORK SCHEDULED</>}
      detail={<><br/>{scheduledRemainingDays}d of {scheduledOriginalEstimate ?? scheduledRemainingDays}d remaining</>}
      sx={{marginBottom: 2}}
      // textColor={getTextColor(workItem.state, scheduledCompletedPercent)}
      // barColor={getBarColor(workItem.state, scheduledCompletedPercent)}
      />
  );
}