import { useState, useContext, useCallback, useMemo } from 'react';
import { produce } from 'immer';

import BacklogContext from './BacklogContext';
import Backlog from '../model/Backlog';
import WorkItem from '../model/WorkItem';

import * as WorkApi from 'azure-devops-node-api/WorkApi';
import * as CoreInterfaces from 'azure-devops-node-api/interfaces/CoreInterfaces';
import * as WorkItemTrackingInterfaces from 'azure-devops-node-api/interfaces/WorkItemTrackingInterfaces';
import * as WitApi from "azure-devops-node-api/WorkItemTrackingApi";
import DevOpsContext from './DevOpsContext';
import WorkItemMap from '../model/WorkItemMap';
import Cache from '../model/Cache';
import { sleep } from '../Util';
import useEffectAsync from './hooks/UseEffectAsync';

const PROFILE = false;

type BacklogProviderProps = {
  teamContext: CoreInterfaces.TeamContext,
  children?: React.ReactChild | React.ReactChild[];
};

export default function BacklogProvider(props: BacklogProviderProps) {
  const [devOpsApi] = useContext(DevOpsContext);
  const [backlog, setBacklog] = useState(() => new Backlog());
  const [workApi, setWorkApi] = useState<WorkApi.IWorkApi>();
  const [witApi, setWitApi] = useState<WitApi.IWorkItemTrackingApi>();
  // const [backlogVersion, setBacklogVersion] = useState(0);
  const loadSet = useMemo(() => new Set<number>(), []);
  const cache = useMemo(() => new Cache(), []);
  // const batchSize = 3;

  useEffectAsync(async signal => {
    if (!devOpsApi) {
      console.log("Waiting for DevOps API to be authenticated");
      return;
    }
    console.log("Getting DevOps APIs");
    let workApi = await devOpsApi.getWorkApi();
    if (signal.aborted) return;
    setWorkApi(workApi);
    let witApi = await devOpsApi.getWorkItemTrackingApi();
    if (signal.aborted) return;
    setWitApi(witApi);
  }, [devOpsApi]);

  useEffectAsync(async signal => {
    // Skip effect until the work API is available
    if (!workApi) return;
    console.log("Work and WIT APIs initialized");

    // Get the objectives and add to the queue for processing
    let { workItemLinks } = await cache.getBacklog(props.teamContext, props.teamContext.team !== "Reporting and Service Maturity" ? "objectives" : "keyResults");

    // Ignore cache for now
    // if (!workItemLinks?.length) {
      if (!workApi) {
        console.log("Work API no longer assigned, skipping");
      } else if (!signal.aborted) {
        console.log("Loading remote items for backlog: " + JSON.stringify(props.teamContext));
        workItemLinks = (await workApi?.getBacklogLevelWorkItems(props.teamContext, props.teamContext.team !== "Reporting and Service Maturity" ? "OSG.ObjectiveCategory" : "OSG.KeyResultCategory"))?.workItems ?? [];
        await cache.putBacklog(props.teamContext, props.teamContext.team !== "Reporting and Service Maturity" ? "objectives" : "keyResults", workItemLinks);
      }
    // }

    if (signal.aborted) return;

    setBacklog(prevState => produce(prevState, draft => {
      draft.root.childIds = workItemLinks?.reduce((values: number[], item) => {
        item.target && item.target.id && values.push(item.target.id);
        return values;
      }, []) ?? [];
    }));

    // Process the backlog in batches
    // for(const batches of batchArray(backlogLevelWorkItems, batchSize)) {
    //   // Wait on the entire batch to complete before starting the next batch
    //   await Promise.all(
    //     batches.map(item => item.target?.id ? loadWorkItem(item.target.id, 0) : null)
    //   );
    // }

    // setBacklogVersion(prevState => prevState + 1);
  }, [props.teamContext, workApi, cache]);

  // TODO: Refactor to just use the call below
  const fetchWorkItem = useCallback(async (id: number, useCache: boolean = true, asOf?: Date) => {
    // Always get the cache entry because if it exists we have to update it even if not using the cached request
    let { workItem, cacheEntry } = await cache.getWorkItem(props.teamContext, id, asOf);

    if (!workItem || !useCache) {
      if (!witApi) {
        console.error("Work Item Tracking API not initialized");
        return undefined;
      }

      try {
        if (PROFILE) console.profile("witApi.getWorkItem: " + id);
        workItem = await witApi.getWorkItem(id, undefined, asOf, WorkItemTrackingInterfaces.WorkItemExpand.Relations);
        if (PROFILE) console.profileEnd("witApi.getWorkItem: " + id);
        if (!workItem) return undefined;
      } catch (error: any) {
        console.error(error);
        return undefined;
      }
    }

    cache.putWorkItem(props.teamContext, workItem, asOf);

    return WorkItem.parseServiceDocument(workItem, cacheEntry?.updatedAt ?? new Date());
  }, [witApi, props.teamContext, cache]);

  const fetchWorkItems = useCallback(async (ids: number[], useCache: boolean = true, asOf?: Date) => {
    let results: WorkItem[] = [];
    let uncachedIds = [];

    if (!witApi) {
      console.error("Work Item Tracking API not initialized");
      return [];
    }

    // Get items from cache
    if (useCache) {
      for (const id of ids) {
        let { workItem, cacheEntry } = await cache.getWorkItem(props.teamContext, id, asOf);
        if (workItem && workItem.id && cacheEntry && cacheEntry.updatedAt) {
          results.push(WorkItem.parseServiceDocument(workItem, cacheEntry.updatedAt));
          continue;
        }
        uncachedIds.push(id);
      };
    } else {
      uncachedIds = ids;
    }

    // Get items from service
    if (uncachedIds.length) {
      try {
        let workItems = await witApi.getWorkItems(uncachedIds, undefined, asOf, WorkItemTrackingInterfaces.WorkItemExpand.Relations, WorkItemTrackingInterfaces.WorkItemErrorPolicy.Omit);
        let index = 0;
        for (const serviceItem of workItems) {
          if (!serviceItem) {
            console.warn("Unable to fetch " + uncachedIds[index]);
            continue;
          }
          cache.putWorkItem(props.teamContext, serviceItem, asOf);
          results.push(WorkItem.parseServiceDocument(serviceItem, new Date()));
          index++;
        }
      } catch (error: any) {
        console.error(error);
        return [];
      }
    }

    return results;
  }, [props.teamContext, witApi, cache]);

  const lockItem = useCallback(async (id: number, abortSignal: AbortSignal) => {
    // If this item is already loading then wait for it to complete
    let fail = false;
    setTimeout(() => fail = true, 1000 * 10);
    while (!abortSignal.aborted && loadSet.has(id)) {
      sleep();
      if (fail) {
        console.error("Timeout waiting for lock: " + id);
        return false;
      }
    }
    if (abortSignal.aborted) { // Abort may have occured during fetch
      return false;
    }
    loadSet.add(id);
    return true;
  }, [loadSet]);

  const unlockItem = useCallback((id: number) => {
    loadSet.delete(id);
  }, [loadSet]);

  const upsertBacklogWorkItem = useCallback((draftBacklogMap: WorkItemMap, workItem: WorkItem) => {
    const currentId = workItem.id;

    if (PROFILE) console.profile("addWorkItemToBacklog.assignWorkItem: " + currentId);
    let draftWorkItem: WorkItem | undefined = draftBacklogMap.get(currentId);
    if (draftWorkItem) {
      // Don't assign children because we need the proxy refs to remain intact.
      // If necessary, children could be cleared by setting the array length to 0.
      const { children, ...other } = workItem;
      Object.assign(draftWorkItem, other);
    } else {
      draftWorkItem = workItem;
    }
    if (PROFILE) console.profileEnd("addWorkItemToBacklog.assignWorkItem: " + currentId);

    // Update parent
    if (PROFILE) console.profile("addWorkItemToBacklog.updateParent: " + currentId);
    let parent = draftBacklogMap.findParent(draftWorkItem.id);
    if (parent && draftWorkItem.parentId !== parent.id) { // Detect parent change
      // If the item has no parent or it does and the parent is not found
      if (!draftWorkItem.parentId || !draftBacklogMap.get(draftWorkItem.parentId)) {
        if (parent.id !== Backlog.ROOT_ID) { // Don't remove it from the root since it would just get added back
          parent.removeChild(draftWorkItem.id);
          parent = undefined;
        }
      }
    }
    if (parent && parent.children.findIndex(child => child.id === draftWorkItem?.id) === -1) { // Detect parent not linked to instance
      parent.addChild(draftWorkItem);
    }
    if (!parent) { // Detect no existing parent has link
      if (draftWorkItem.parentId) parent = draftBacklogMap.get(draftWorkItem.parentId);
      if (!parent) parent = draftBacklogMap.get(Backlog.ROOT_ID);
      // if (!parent) throw new Error("Root item not found in backlog. Logic error.");
      if (parent) parent.addChild(draftWorkItem);
    }
    if (PROFILE) console.profileEnd("addWorkItemToBacklog.updateParent: " + currentId);

    // Detect if children are under the wrong parent
    if (PROFILE) console.profile("addWorkItemToBacklog.updateChildren: " + currentId);
    for (const childId of draftWorkItem.childIds) {
      parent = draftBacklogMap.findParent(childId);
      if (parent && parent.id !== draftWorkItem.id) { // Detect parent change
        parent.removeChild(childId);
        parent = undefined;
      }
      if (parent && parent.children.findIndex(child => child.id === childId) === -1) { // Detect parent not linked to instance
        let child = draftBacklogMap.get(childId);
        if (child) { // If the child is available then link it
          parent.addChild(child);
        }
      }
      if (!parent) { // No existing parent so add the child to this item
        let child = draftBacklogMap.get(childId);
        if (child) {
          draftWorkItem.addChild(child);
        }
      }
    }
    // Remove children that are no longer present
    for (const child of [...draftWorkItem.children]) {
      if (draftWorkItem.childIds.indexOf(child.id) === -1) {
        draftWorkItem.removeChild(child.id);
      }
    }
    if (PROFILE) console.profileEnd("addWorkItemToBacklog.updateChildren: " + currentId);

    // Add the item to the map
    // TODO: Likely need to update the map for changes above due to parent caching. Disabling cache for now.
    draftBacklogMap.set(draftWorkItem.id, draftWorkItem);
  }, []);

  const loadWorkItemMap = useCallback(async (id: number, childLevels: number, abortSignal: AbortSignal, useCache: boolean = true, updateRelations: boolean = false, asOf?: Date) => {
    let queue = [{ level: 0, id: id }];
    let map = new WorkItemMap();

    while (queue.length) {
      // Get from queue
      const queueItem = queue.shift();
      if (queueItem === undefined) break;
      const currentId = queueItem.id;

      // console.trace(id, "loadWorkItem.lockItem start", currentId);
      // If this item is already loading then wait for it to complete
      if (!await lockItem(currentId, abortSignal)) {
        return undefined;
      }
      // console.trace(id, "loadWorkItem.lockItem end", currentId);

      // console.log(id, "loadWorkItem.get start", currentId);
      let workItem: WorkItem | undefined = map.get(currentId); // check local set of this operation
      if (!workItem && useCache && !asOf) workItem = backlog.findById(currentId); // check global state
      if (!workItem || workItem.isExpired) workItem = await fetchWorkItem(currentId, useCache, asOf); // fetch if needed
      if (!workItem) { unlockItem(currentId); continue; }
      map.set(currentId, workItem);
      // console.log(id, "loadWorkItem.get end", currentId);

      if (childLevels > queueItem.level) {
        let childLocks: number[] = [];

        // console.log(id, "loadWorkItem.lock children start", currentId);
        // Lock children
        for (const childId of workItem.childIds) {
          if (!await lockItem(childId, abortSignal)) {
            childLocks.forEach(item => unlockItem(item));
            unlockItem(currentId);
            return undefined;
          } else {
            childLocks.push(childId);
          }
        }
        // console.log(id, "loadWorkItem.lock children end", currentId);

        // Determine the fetch set
        let childIds = workItem.childIds.filter(childId => {
          let workItem: WorkItem | undefined = map.get(childId);
          if (!workItem && useCache && !asOf) workItem = backlog.findById(childId);
          if (!workItem || workItem.isExpired) return true;
          map.set(childId, workItem);
          return false;
        });

        // Fetch
        let children = await fetchWorkItems(childIds, useCache, asOf);
        for (const child of children) {
          map.set(child.id, child);
        }

        // Queue all children
        queue.push(...(workItem.childIds.map(childId => { return { level: queueItem.level + 1, id: childId } })));

        // Unlock children
        childLocks.forEach(item => unlockItem(item));
      }

      // console.log(id, "loadWorkItem.unlock start", currentId);
      unlockItem(currentId);
      // console.log(id, "loadWorkItem.unlock end", currentId);
    }

    // console.log(id, "loadWorkItem.update relations start");
    // TODO: Likely lock concerns
    if (updateRelations) {
      for (const item of map.values()) {
        upsertBacklogWorkItem(map, item);
      }
    }
    // console.log(id, "loadWorkItem.update relations end");

    return map;
  }, [fetchWorkItem, fetchWorkItems, lockItem, unlockItem, upsertBacklogWorkItem]);

  const loadWorkItem = useCallback(async (id: number, childLevels: number, abortSignal: AbortSignal, useCache: boolean = true) => {
    if (!witApi) {
      console.error("Work Item Tracking API not initialized");
      return undefined;
    }

    let map = await loadWorkItemMap(id, childLevels, abortSignal, useCache);
    if (abortSignal.aborted) return undefined;

    let workItem: WorkItem | undefined;
    setBacklog(prevState => {
      let newState = produce(prevState, draftBacklog => {
        // Traverse the draft tree once to build a fast lookup by ID
        if (PROFILE) console.profile("addWorkItemToBacklog.buildMap: " + id);
        let draftBacklogMap = draftBacklog.buildMap();
        map?.forEach(item => {
          if (!abortSignal.aborted) {
            upsertBacklogWorkItem(draftBacklogMap, item);
          }
        });
        if (PROFILE) console.profileEnd("addWorkItemToBacklog.buildMap: " + id);
      });
      workItem = newState.findById(id);
      return newState;
    });
    await sleep();
    return workItem;
  }, [loadWorkItemMap, upsertBacklogWorkItem, witApi]);

  return (
    <BacklogContext.Provider value={{ backlog, setBacklog, loadWorkItem, loadWorkItemMap }}>
      {props.children}
    </BacklogContext.Provider>
  );
}