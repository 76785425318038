import WorkItemType from "../model/WorkItemType";
import { blue, green, purple, red, yellow, orange } from "@mui/material/colors";

export const IconColor = {
  [WorkItemType.Backlog]: orange[500],
  [WorkItemType.Objective]: red[500],
  [WorkItemType.KeyResult]: green[500],
  [WorkItemType.Scenario]: purple[900],
  [WorkItemType.Feature]: blue[200],
  [WorkItemType.Deliverable]: blue[900],
  [WorkItemType.Task]: yellow[700]
};

export const BackgroundColor = {
  [WorkItemType.Backlog]: orange[50],
  [WorkItemType.Objective]: red[50],
  [WorkItemType.KeyResult]: green[50],
  [WorkItemType.Scenario]: purple[50],
  [WorkItemType.Feature]: blue[50],
  [WorkItemType.Deliverable]: blue[50],
  [WorkItemType.Task]: yellow[50]
};