import Box from "@mui/material/Box";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import yellow from "@mui/material/colors/yellow";
import LinearProgress from "@mui/material/LinearProgress";
import { SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

type WorkItemMetricProps = {
  percentage: number,
  state: JSX.Element | string,
  detail: JSX.Element | string,
  textColor?: string,
  barColor?: "inherit" | "success" | "warning" | "error" | "info" | "primary" | "secondary" | undefined,
  sx?: SxProps
};

function getThresholdTextColor(percentage: number) {
  return percentage >= 90 ? green[700] : percentage >= 50 ? yellow[900] : red[500];
}

function getThresholdBarColor(percentage: number) {
  return percentage >= 90 ? 'success' : percentage >= 50 ? 'warning' : 'error';
}

export default function WorkItemMetric({percentage, state, detail, textColor = getThresholdTextColor(percentage), barColor = getThresholdBarColor(percentage), sx}: WorkItemMetricProps) {
  return (
    <Box sx={{ display: 'flex', marginLeft: 2, marginRight: 2, ...sx }}>
      <Box sx={{ width: 110 }}>
        <Typography fontSize={24} color={textColor}>
          {percentage}%
        </Typography>
        <Typography fontSize={12} color={textColor}>
          {state}
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ color: 'text.secondary', marginLeft: 'auto', textAlign: 'right' }}>
          {detail}
        </Typography>
        <LinearProgress variant='determinate' value={percentage} color={barColor} />
      </Box>
    </Box>
  );
}