import { useEffect, useRef } from "react";

export default function useEffectAsync(asyncFn: (signal: AbortSignal) => Promise<void>, deps?: React.DependencyList, onSuccess?: () => void) {
  let abortController = useRef(new AbortController());

  useEffect(() => {
    abortController.current.abort();
    let localAbortController = new AbortController();
    abortController.current = localAbortController;

    asyncFn(localAbortController.signal).then(() => {
      if (!localAbortController.signal.aborted && onSuccess) onSuccess();
    });

    return () => localAbortController.abort();
    /* eslint react-hooks/exhaustive-deps: "off" */
  }, deps);
}