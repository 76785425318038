enum WorkItemType {
  Backlog = "Backlog",
  Objective = "Objective",
  KeyResult = "KeyResult",
  Feature = "Feature",
  Scenario = "Scenario",
  Deliverable = "Deliverable",
  Task = "Task"
}

namespace WorkItemType {
  export function toString(type: WorkItemType): string {
    switch (type) {
      case WorkItemType.KeyResult:
        return "Key Result";
      default:
        return WorkItemType[type];
    }
  }

  export function parse(type: string): WorkItemType | undefined {
    switch (type) {
      case "Backlog":
        return WorkItemType.Backlog;
      case "Objective":
        return WorkItemType.Objective;
      case "Key Result":
        return WorkItemType.KeyResult;
      case "Scenario":
        return WorkItemType.Scenario;
      case "Feature":
        return WorkItemType.Feature;
      case "Deliverable":
        return WorkItemType.Deliverable;
      case "Task":
        return WorkItemType.Task;
      default:
        return undefined;
    }
  }
}

export default WorkItemType;