import WorkItem from "./WorkItem";

export default class WorkItemMap extends Map<number, WorkItem> {
  parents: Map<number, number> = new Map<number, number>(); // maps child ID to parent ID

  public constructor(entries?: readonly (readonly [number, WorkItem])[] | null | undefined) {
    super(entries);
  }

  public findParent(childId: number): WorkItem | undefined {
    // const parentId = this.parents.get(childId);
    // return parentId !== undefined ? this.get(parentId) : undefined;
    return this.findParentByIteration(childId);
  }

  // public visit(predicate: (item: WorkItem) => void) {
  //   let parent:WorkItem | undefined;
  //   let iter = this.values();
  //   let n = iter.next();
  //   while (!n.done) {
  //     predicate(n.value);
  //     n = iter.next();
  //   }
  //   return parent;
  // }

  public findParentByIteration(childId: number) {
    let parent:WorkItem | undefined;
    let iter = this.values();
    let n = iter.next();
    while (!n.done) {
      let item = n.value;
      if (item.childIds.includes(childId)) {
        parent = item;
        break;
      }
      n = iter.next();
    }
    return parent;
  }

  public set(key: number, value: WorkItem) {
    for (const childId of value.childIds) {
      this.parents.set(childId, value.id);
    }

    return super.set(key, value);
  }

  public delete(key: number): boolean {
    // Remove children with this as a parent
    let value = this.get(key);
    if (value) {
      for (const childId of value.childIds) {
        this.parents.delete(childId);
      }
    }

    // Remove entry for parent of self
    this.parents.delete(key);

    // Remove entry from base map
    return super.delete(key);
  }
}