import WorkItemType from "../model/WorkItemType";

import ObjectiveIcon from '@mui/icons-material/Diamond';
import KeyResultIcon from '@mui/icons-material/InsertChart';
import DeliverableIcon from '@mui/icons-material/EmojiEvents';
import TaskIcon from '@mui/icons-material/Task';
import SvgIcon from '@mui/material/SvgIcon';
import { IconColor } from "./WorkItemColor";
import { SxProps } from "@mui/material";

function ScenarioAndFetureIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M5 16L3 5l5.5 5L12 4l3.5 6L21 5l-2 11H5m14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14v1Z" />
    </SvgIcon>
  );
}

export default function WorkItemIcon({type, sx, ...other}: {type: WorkItemType, sx: SxProps }) {
  switch(type)
  {
    case WorkItemType.Objective:
    default:
      return <ObjectiveIcon {...other} sx={{ color: IconColor[type], ...sx }} />
    case WorkItemType.KeyResult:
      return <KeyResultIcon {...other} sx={{ color: IconColor[type], ...sx }} />
    case WorkItemType.Scenario:
      return <ScenarioAndFetureIcon {...other} sx={{ color: IconColor[type], ...sx }} />
    case WorkItemType.Feature:
      return <ScenarioAndFetureIcon {...other} sx={{ color: IconColor[type], ...sx }} />
    case WorkItemType.Deliverable:
      return <DeliverableIcon {...other} sx={{ color: IconColor[type], ...sx }} />
    case WorkItemType.Task:
      return <TaskIcon {...other} sx={{ color: IconColor[type], ...sx }} />
  }
}