import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import WorkItem from "../model/WorkItem";
import WorkItemType from "../model/WorkItemType";
import WorkItemIcon from "./WorkItemIcon";

const ChildCountItem = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.h4,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'larger'
}));

type WorkItemChildCountsProps = {
  workItem: WorkItem,
  allNodes: WorkItem[]
}

export default function WorkItemChildCounts({ workItem, allNodes }: WorkItemChildCountsProps) {
  const [scenariosCount, setScenariosCount] = useState(0);
  const [featuresCount, setFeaturesCount] = useState(0);
  const [deliverablesCount, setDeliverablesCount] = useState(0);
  const [tasksCount, setTasksCount] = useState(0);

  useEffect(() => {
    type ItemTotals = {
      scenariosCount: number,
      featuresCount: number,
      deliverablesCount: number,
      tasksCount: number
    };

    let totals = allNodes.reduce((totals: ItemTotals, item: WorkItem) => {
      if (item === workItem)
        return totals;

      switch(item.type) {
        case WorkItemType.Scenario:
          totals.scenariosCount++;
          break;
        case WorkItemType.Feature:
          totals.featuresCount++;
          break;
        case WorkItemType.Deliverable:
          totals.deliverablesCount++;
          break;
        case WorkItemType.Task:
          totals.tasksCount++;
          break;
      }
      return totals;
    }, { scenariosCount: 0, featuresCount: 0, deliverablesCount: 0, tasksCount: 0 });

    setScenariosCount(totals.scenariosCount);
    setFeaturesCount(totals.featuresCount);
    setDeliverablesCount(totals.deliverablesCount);
    setTasksCount(totals.tasksCount);
  }, [allNodes, workItem]);

  return (
    <Stack direction="row" spacing={2} sx={{ marginLeft: 5, marginRight: 5, marginTop: 3, display: 'flex', justifyContent: 'center' }}>
      {
        workItem.type !== WorkItemType.Scenario && workItem.type !== WorkItemType.Feature && workItem.type !== WorkItemType.Deliverable && workItem.type !== WorkItemType.Task &&
        <ChildCountItem key="1">
          <WorkItemIcon key="1" type={WorkItemType.Scenario} sx={{ transform: 'scale(1.25)', marginRight: 1 }} />
          {scenariosCount}
        </ChildCountItem>
      }
      {
        workItem.type !== WorkItemType.Scenario && workItem.type !== WorkItemType.Feature && workItem.type !== WorkItemType.Deliverable && workItem.type !== WorkItemType.Task &&
        <ChildCountItem key="2">
          <WorkItemIcon key="2" type={WorkItemType.Feature} sx={{ transform: 'scale(1.25)', marginRight: 1 }} />
          {featuresCount}
        </ChildCountItem>
      }
      {
        workItem.type !== WorkItemType.Deliverable && workItem.type !== WorkItemType.Task &&
        <ChildCountItem key="3">
          <WorkItemIcon key="3" type={WorkItemType.Deliverable} sx={{ transform: 'scale(1.25)', marginRight: 1 }} />
          {deliverablesCount}
        </ChildCountItem>
      }
      {
        workItem.type !== WorkItemType.Task &&
        <ChildCountItem key="4">
          <WorkItemIcon key="4" type={WorkItemType.Task} sx={{ transform: 'scale(1.25)', marginRight: 1 }} />
          {tasksCount}
        </ChildCountItem>
      }
    </Stack>
  );
}