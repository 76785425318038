import WorkItemState from './WorkItemState';
import WorkItemType from './WorkItemType';
import * as WorkItemTrackingInterfaces from 'azure-devops-node-api/interfaces/WorkItemTrackingInterfaces';
import { searchTree, searchTreeAll } from '../Util';
import { immerable } from 'immer';
import yaml from "js-yaml";
import InsightsDocument from './InsightsDocument';
import TaskedMeasures from '../components/measures/TaskedMeasures';
import IterationRange from './IterationRange';

export default class WorkItem {
  [immerable] = true; // Due to React immutability, this object cannot have bidirectional links, like to a parent

  public static MAX_AGE: number = 1000 * 60 * 60 * 24;
  public static FUTURE_ITERATION = Number.POSITIVE_INFINITY;

  public id: number;
  public title: string;
  public type: WorkItemType;
  public state: WorkItemState = WorkItemState.Proposed;
  public originalEstimate?: number;
  public remainingDays?: number;
  public iteration?: string;
  public parentId: number | null = null;
  public childIds: number[] = [];
  public children: WorkItem[] = [];
  public serviceDocument: WorkItemTrackingInterfaces.WorkItem | null = null;
  public loadedDateTime: Date = new Date();
  public viewModelVersion: number = 0;
  public insightsDocument: InsightsDocument | null = null;

  public constructor(id: number, title: string, type: WorkItemType) {
    this.id = id;
    this.title = title;
    this.type = type;
  }

  // public constructor(init?:Partial<WorkItem>) {
  //     Object.assign(this, init);
  // }

  public addChild(itemOrId: WorkItem | number) {
    let childId: number;
    if (itemOrId instanceof WorkItem) {
      let workItem = itemOrId as WorkItem;
      let index = this.children.findIndex(item => item.id === workItem.id);
      if (index === -1) {
        // console.log("addChild: " + workItem.id + " to: " + this.id);
        this.children.push(workItem);
        // console.log("addChild result:");
        // this.log();
      }
      childId = workItem.id;
    } else {
      childId = itemOrId as number;
    }
    let index = this.childIds.indexOf(childId);
    if (index === -1) {
      this.childIds.push(childId);
    }
  }

  public removeChild(childId: number) {
    let index = this.children.findIndex(item => item.id === childId);
    if (index !== -1) {
      // console.log("removeChild: " + childId + " from: " + this.id);
      this.children.splice(index, 1);
      // console.log("removeChild result:");
      // this.log();
    }
    index = this.childIds.indexOf(childId);
    if (index !== -1) {
      this.childIds.splice(index, 1);
    }
  }

  public findAll(predicate: (item: WorkItem) => boolean, skip: undefined | ((item: WorkItem) => boolean) = undefined) {
    return searchTreeAll<WorkItem>(this, predicate, skip);
  }

  public findFirst(predicate: (item: WorkItem) => boolean, skip: undefined | ((item: WorkItem) => boolean) = undefined) {
    return searchTree<WorkItem>(this, predicate, skip) ?? undefined;
  }

  public findById(id: number) {
    return this.findFirst(item => item.id === id);
  }

  public get bestKnownEstimate() {
    let estimate = this.originalEstimate ?? 0;
    let remaining = this.remainingDays ?? 0;
    return remaining > estimate ? remaining : estimate;
  }

  public get bestKnownRemainingDays() {
    if (this.state === WorkItemState.Completed || this.state === WorkItemState.Cut)
      return 0;

    return this.remainingDays ?? this.originalEstimate ?? 0;
  }

  public get isScheduled() {
    return this.iteration && this.iteration !== 'OS\\Future';
  }

  public get isCommitted() {
    return WorkItemState.isCommitted(this.state);
  }

  public get isExpired() {
    return Date.now() - this.loadedDateTime.valueOf() > WorkItem.MAX_AGE;
  }

  public parseInsightsSchema(text: string) {
    const html = new DOMParser().parseFromString(text, 'text/html');

    let descText = html.body.innerHTML;
    descText = descText.replace(/<spa[^>]+>/g, ''); // Remove span elements
    descText = descText.replace(/<\/spa[^>]+>/g, ''); // Remove span elements
    descText = descText.replace(/<\/[^>]+>/g, '\n'); // Convert closing elements to newline
    descText = descText.replace(/<[^>]+>/g, ''); // Remove opening elements
    descText = descText.replace(/&nbsp;/g, ' '); // Convert code to spaces

    // Find the YAML docs
    const yamlDocs = descText.split('\n---');

    // For now assume only one doc per record
    if (yamlDocs.length === 2) {
      try {
        const yamlDoc: any = yaml.load(yamlDocs[1]);

        if (yamlDoc.schema && yamlDoc.schema === "insights/v1") {
          this.insightsDocument = yamlDoc;
        }
      } catch (e:any) {
        if (e && e.reason && e.reason.indexOf("end of the stream") === -1) {
          console.warn(e);
          console.log(yamlDocs[1]);
          console.log(html.body.innerHTML);
        }
      }
    }
  }

  public static parseServiceDocument(serviceDocument: WorkItemTrackingInterfaces.WorkItem, updatedAt: Date = new Date()) {
    if (!serviceDocument || serviceDocument.id === undefined) {
      throw new Error("Service document had no id");
    }
    const id = serviceDocument.id;

    // Create a model work item
    let workItem = new WorkItem(
      id,
      serviceDocument.fields?.["System.Title"],
      WorkItemType.parse(serviceDocument.fields?.["System.WorkItemType"]) ?? WorkItemType.Task
    );
    Object.assign(workItem, {
      state: WorkItemState.parse(serviceDocument.fields?.["System.State"]) ?? WorkItemState.Proposed,
      iteration: serviceDocument.fields?.["System.IterationPath"],
      remainingDays: serviceDocument.fields?.["OSG.RemainingDays"],
      originalEstimate: serviceDocument.fields?.["Microsoft.VSTS.Scheduling.OriginalEstimate"],
      serviceDocument: serviceDocument,
      loadedDateTime: updatedAt
    });

    // Populate insights schema
    // if (PROFILE) console.profile("workItemModel.parseInsightsSchema: " + id);
    workItem.parseInsightsSchema(serviceDocument.fields?.["System.Description"]);
    // if (PROFILE) console.profileEnd("workItemModel.parseInsightsSchema: " + id);

    // Freeze service doc so it is not updatable for performance
    // if (PROFILE) console.profile("object.freeze: " + id);
    Object.freeze(workItem.serviceDocument);
    // if (PROFILE) console.profileEnd("object.freeze: " + id);

    // Update parent ID
    let parentRelations = serviceDocument.relations?.filter(
      relation => relation.attributes?.["name"] === "Parent") ?? [];
    if (parentRelations.length > 0) {
      workItem.parentId = parseInt(parentRelations[0].url?.substring(parentRelations[0].url?.lastIndexOf('/') + 1) ?? "0");
    }

    // Update child IDs
    let childRelations = serviceDocument.relations?.filter(
      relation => relation.attributes?.["name"] === "Child") ?? [];
    for (const relation of childRelations) {
      let childId = parseInt(relation.url?.substring(relation.url?.lastIndexOf('/') + 1) ?? "0");
      workItem.childIds.push(childId);
    }

    return workItem;
  }

  public log(node?: WorkItem, indent?: number) {
    if (!node) node = this;
    if (!indent) indent = 1;
    if (indent >= 8) {
      console.warn("Too many levels deep, likely an error: " + node.id);
      return;
    }
    console.log('--' + Array(indent).join('--'), node.id);
    if (node.children) {
      indent++;
      node.children.forEach(child => this.log(child, indent));
    }
    // if (tree.indexOf(node) === tree.length - 1) {
    //   indent--;
    // }
  }

  get allNodes() {
    let allNodes = this.findAll(item => true, item => item.state === WorkItemState.Cut);
    return allNodes;
  }

  get leafNodes() {
    let allNodes = this.allNodes;

    function isSetEmptyOrCut(items: WorkItem[]) {
      for (const item of items) {
        if (item.state !== WorkItemState.Cut) {
          return false;
        }
      }
      return true;
    }

    // Leaf nodes are the set that have no uncut children
    let leafNodes = allNodes.filter(item => isSetEmptyOrCut(item.children));
    return leafNodes;
  }

  public get iterationAsNumber() {
    let iteration = this.iteration?.replace("OS\\", "") ?? "Future";

    if (iteration === "Future") {
      return WorkItem.FUTURE_ITERATION;
    }
    else {
      let num = parseInt(iteration);
      if (!isNaN(num)) {
        return num;
      } else {
        return WorkItem.FUTURE_ITERATION;
      }
    }
  }

  public getScheduledIterationsAsNumber(nodes: WorkItem[] = this.allNodes) {
    let iterations = nodes.reduce((results: number[], item: WorkItem) => {
      results.push(item.iterationAsNumber);
      return results;
    }, []);
    let min = Math.min(...iterations);
    let max = Math.max(...iterations);
    if (max === Number.NEGATIVE_INFINITY) max = Number.POSITIVE_INFINITY;
    return { start: min, end: max }
  }

  public getScheduledIterationsAsFormattedString(nodes: WorkItem[] = this.allNodes) {
    let { start, end } = this.getScheduledIterationsAsNumber(nodes);
    let startString = start === WorkItem.FUTURE_ITERATION ? "Future" : start.toString();
    let endString = end === WorkItem.FUTURE_ITERATION ? "Future" : end.toString();
    if (startString === endString) return startString;
    return startString + " - " + endString;
  }

  public getScheduledIterationsExcludingFuture(nodes: WorkItem[] = this.allNodes) {
    let iterations = nodes.reduce((results: number[], item: WorkItem) => {
      if (item.iterationAsNumber !== WorkItem.FUTURE_ITERATION)
        results.push(item.iterationAsNumber);
      return results;
    }, []);
    if (!iterations.length) {
      return undefined;
    }
    let min = Math.min(...iterations);
    let max = Math.max(...iterations);
    return { start: min, end: max }
  }

  public getScheduledIterationsAsDateRange(nodes: WorkItem[] = this.allNodes) {
    let iterations = this.getScheduledIterationsExcludingFuture(nodes);
    if (!iterations) return undefined;
    return ({
      start: WorkItem.getIterationAsDateRange(iterations.start)?.start ?? new Date(),
      end: WorkItem.getIterationAsDateRange(iterations.end)?.end ?? new Date()
    });
  }

  // public get interpretedIterationsAsDateRange() {
  //   const { start, end } = this.scheduledIterationsAsDateRange;
  //   const { start: itemStart, end: itemEnd } = WorkItem.getIterationAsDateRange(this.iterationAsNumber);
  //   return ({
  //     start: start ?? itemStart ?? new Date(),
  //     end: end ?? itemEnd ?? new Date()
  //   });
  // }

  public static getIterationAsDateRange(iteration: number) {
    // If iteration is future then pick a date 2 years from now
    if(iteration === WorkItem.FUTURE_ITERATION) {
      // iteration = (((new Date().getFullYear() + 1) - 2000) * 100) + 11; // December one year from now
      return undefined; //{ start: undefined, end: undefined };
    }
    let startYear = 2000 + parseInt(iteration.toString().substring(0, 2));
    let startMonth = parseInt(iteration.toString().substring(2,4)) - 1; // Months are zero indexed
    let startDay = 1;
    let endYear = startYear
    let endMonth = startMonth + 1; // This plus day 0 = last day of the month
    let endDay = 0;
    return { start: new Date(startYear, startMonth, startDay), end: new Date(endYear, endMonth, endDay) };
  }

  public getCompletedMetric(leafNodes: WorkItem[] = this.leafNodes) {
    let total = leafNodes.reduce((total: number, item: WorkItem) => {
      return total + item.bestKnownRemainingDays;
    }, 0);
    let remainingDays = total;

    total = leafNodes.reduce((total: number, item: WorkItem) => {
      return total + item.bestKnownEstimate;
    }, 0);
    let originalEstimate = total;

    let completedDays = originalEstimate - remainingDays;
    let completedPercentage = originalEstimate ? (100 * completedDays / originalEstimate) : 0;
    completedPercentage = parseInt(completedPercentage.toFixed());
    return { remainingDays, originalEstimate, completedDays, completedPercentage }
  }

  public getTaskedMetric(leafNodes: WorkItem[] = this.leafNodes, iterationRange: IterationRange): TaskedMeasures {
    // Items with no children that aren't tasks themselves or items that already have tasks
    let reducedNodes = this.findAll(item =>
      IterationRange.contains(iterationRange, item.iterationAsNumber) && (
        (item.children.length === 0 && item.type !== WorkItemType.Task)
        || item.children.findIndex(item => item.type === WorkItemType.Task) !== -1
      ),
      item => item.state === WorkItemState.Cut);
    let taskableItemCount = reducedNodes.length;

    // Items with tasks as children
    reducedNodes = this.findAll(item =>
      IterationRange.contains(iterationRange, item.iterationAsNumber) && (
        item.children.findIndex(item => item.type === WorkItemType.Task) !== -1
      ),
      item => item.state === WorkItemState.Cut);
    let taskedItemCount = reducedNodes.length;

    reducedNodes = leafNodes.filter(item => item.type === WorkItemType.Task && IterationRange.contains(iterationRange, item.iterationAsNumber));
    let taskedDays = reducedNodes.reduce((total: number, item: WorkItem) => {
      return total + item.bestKnownEstimate;
    }, 0);

    reducedNodes = leafNodes.filter(item => item.type !== WorkItemType.Task && IterationRange.contains(iterationRange, item.iterationAsNumber));
    let untaskedItemCount = reducedNodes.length;
    let untaskedDays = reducedNodes.reduce((total: number, item: WorkItem) => {
      return total + item.bestKnownEstimate;
    }, 0);

    let taskedItemPercentage = taskableItemCount ? (100 * taskedItemCount / taskableItemCount) : 100;

    return { taskableItemCount, taskedItemCount, untaskedItemCount, taskedDays, untaskedDays, taskedItemPercentage };
  }
}