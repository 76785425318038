import Iteration from "./Iteration";

enum IterationRange {
  All,
  Earlier,
  Current,
  Next,
  Later
}

namespace IterationRange {
  export function contains(iterationRange: IterationRange, iteration: number) {
    switch (iterationRange) {
      case IterationRange.All:
        return true;
      case IterationRange.Earlier:
        return iteration < Iteration.currentIterationAsNumber;
      case IterationRange.Current:
        return iteration === Iteration.currentIterationAsNumber;
      case IterationRange.Next:
        return iteration === Iteration.nextIterationAsNumber;
      case IterationRange.Later:
        return iteration > Iteration.nextIterationAsNumber;
    }
  }
}

export default IterationRange;