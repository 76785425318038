import WorkItem from "../../model/WorkItem";
import * as WorkItemColor from "../WorkItemColor";
import { shadeColor } from "../../Util";

import CompletedMetric from "./CompletedMetric";
import CommittedCompletedMetric from "./CommittedCompletedMetric";
import ScheduledCompletedMetric from "./ScheduledCompletedMetric";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

type CompletedMetricGroupProps = {
  workItem: WorkItem,
  leafNodes: WorkItem[],
};

export default function PlannedMetricGroup({ workItem, leafNodes}: CompletedMetricGroupProps) {
  return (
    <Accordion sx={{backgroundColor: shadeColor(WorkItemColor.BackgroundColor[workItem.type], 0.075)}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header" sx={{flex: 1}}>
        <Box sx={{ width: '100%' }}>
        {/* <Paper elevation={1} sx={{ padding: 2, borderRadius: 0 }}> */}
          <CompletedMetric workItem={workItem} leafNodes={leafNodes} />
        {/* </Paper> */}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{marginBottom: 3}}/>
        <CommittedCompletedMetric workItem={workItem} leafNodes={leafNodes} />
        <ScheduledCompletedMetric workItem={workItem} leafNodes={leafNodes} />
      </AccordionDetails>
    </Accordion>
  );
}