import WorkItem from './WorkItem';
import { immerable } from "immer"
import { searchTree, searchTreeAll } from '../Util';
import WorkItemType from './WorkItemType';
import WorkItemMap from './WorkItemMap';

export default class Backlog {
  [immerable] = true;

  public static ROOT_ID = 0;

  // public items: WorkItem[] = [];
  public root: WorkItem = new WorkItem(Backlog.ROOT_ID, "Backlog", WorkItemType.Backlog);

  public buildMap() {
    const workItemMap = new WorkItemMap();
    const stack = [ this.root ];
    while (stack.length) {
      const node = stack.shift();
      if (node) {
        workItemMap.set(node.id, node);
        node.children && stack.push(...node.children);
      }
    }
    return workItemMap;
  }

  public findParent(childId: number) {
    // Use the child hierarchy as it represents what is loaded and navigable
    return this.findFirst(item => item.children.findIndex(item => item.id === childId) !== -1);
    // return this.findFirst(item => item.childIds.indexOf(childId) !== -1);
  }

  public findAll(predicate: (item: WorkItem) => boolean) {
    // let results: WorkItem[] = [];
    // for (const item of this.root.children) {
    //   results.push(...searchTreeAll(item, predicate));
    // }
    // return results;
    return searchTreeAll(this.root, predicate) ?? undefined;
  }

  public findFirst(predicate: (item: WorkItem) => boolean) {
    // for (const item of this.root.children) {
    //   let node = searchTree(item, predicate);
    //   if (node) return node;
    // }
    // return undefined;
    return searchTree(this.root, predicate) ?? undefined;
  }

  public findById(id: number) {
    return this.findFirst(item => item.id === id);
  }
}