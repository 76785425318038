import CacheEntry from "./CacheEntry";
import { camelize } from '../Util';
import * as WorkItemTrackingInterfaces from 'azure-devops-node-api/interfaces/WorkItemTrackingInterfaces';
import * as CoreInterfaces from 'azure-devops-node-api/interfaces/CoreInterfaces';
import { Database, Q } from '@nozbe/watermelondb'
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'
import schema from '../db/schema'
import migrations from '../db/migrations'
import logger from '@nozbe/watermelondb/utils/common/logger';
logger.silence();

export default class Cache {
  public profileEnabled = false;

  adapter = new LokiJSAdapter({
    schema,
    // (You might want to comment out migrations for development purposes -- see Migrations documentation)
    migrations,
    useWebWorker: false,
    useIncrementalIndexedDB: true,
    // dbName: 'myapp', // optional db name

    // --- Optional, but recommended event handlers:

    onQuotaExceededError: (error) => {
      // Browser ran out of disk space -- offer the user to reload the app or log out
    },
    onSetUpError: (error) => {
      // Database failed to load -- offer the user to reload the app or log out
    },
    extraIncrementalIDBOptions: {
      onDidOverwrite: () => {
        // Called when this adapter is forced to overwrite contents of IndexedDB.
        // This happens if there's another open tab of the same app that's making changes.
        // Try to synchronize the app now, and if user is offline, alert them that if they close this
        // tab, some data may be lost
      },
      onversionchange: () => {
        // database was deleted in another browser tab (user logged out), so we must make sure we delete
        // it in this tab as well - usually best to just refresh the page
        // if (checkIfUserIsLoggedIn()) {
          // window.location.reload()
        // }
      },
    }
  });

  db: Database = new Database({
    adapter: this.adapter,
    modelClasses: [
      CacheEntry
    ]
  });

  // public constructor() {
  //   this.db.write(async () => {
  //     this.db.unsafeResetDatabase();
  //   });
  // }

  getWorkItemCacheId(project: string = "global", id: number, asOf?: Date) {
    let cacheId = [
      "",
      camelize(project),
      "workItems",
      id
    ].join("/");
    if (asOf) {
      cacheId = [cacheId, "asOf", asOf.toUTCString()].join("/")
    }
    return cacheId;
  }

  getBacklogCacheId(teamContext: CoreInterfaces.TeamContext, name: string) {
    return [
      "",
      camelize(teamContext.project ?? "global"),
      camelize(teamContext.team ?? "global"),
      "backlogs"
    ].join("/");
  }

  public async get(id: string): Promise<CacheEntry | undefined> {
    // return undefined;
    let cacheEntry: CacheEntry | undefined;
    try {
      // Always check the cache even if useCache is false because the record
      // cannot be updated without reading the revision first.
      if (this.profileEnabled) console.profile("Cache.get: " + id);
      let results = await this.db.get<CacheEntry>("cache_entries").query(Q.where('cache_id', Q.eq(id))).fetch();
      if (results && results.length === 1) {
        cacheEntry = results[0];
      } else if(results && results.length > 1) {
        console.warn("Work item " + id + " had too many records: " + results.length + ". Discarding cache.");
        for (const result of results) {
          await this.db.write(async () => {
            await result.destroyPermanently();
          });
        }
      }
      if (this.profileEnabled) console.profileEnd("Cache.get: " + id);
    } catch (error: any) {
      console.warn(error);
    }
    return cacheEntry;
  }

  public async put(id: string, data: string, cacheEntry?: CacheEntry): Promise<void> {
    try {
      if (this.profileEnabled) console.profile("Cache.put: " + id);
      await this.db.write(async () => {
        if (cacheEntry) {
          await cacheEntry.update(record => {
            record.data = data;
          });
        } else {
          await this.db.get<CacheEntry>('cache_entries').create(record => {
            record.cacheId = id;
            record.data = data;
          });
        }
      });
      if (this.profileEnabled) console.profileEnd("Cache.put: " + id);
    } catch (error: any) {
      console.warn(error);
    }
  }

  public async getWorkItem(teamContext: CoreInterfaces.TeamContext, id: number, asOf?: Date) {
    const cacheId = this.getWorkItemCacheId(teamContext?.project, id, asOf);
    let workItem: WorkItemTrackingInterfaces.WorkItem | undefined;
    let cacheEntry = await this.get(cacheId);
    try {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate()-1);
      if (cacheEntry && (!cacheEntry.isExpired || (asOf && asOf <= yesterday))) {
        workItem = JSON.parse(cacheEntry.data ?? "") as WorkItemTrackingInterfaces.WorkItem;
      }
    } catch(error: any) {
      console.warn(error);
    }
    return { workItem, cacheEntry };
  }

  public async putWorkItem(teamContext: CoreInterfaces.TeamContext, workItem: WorkItemTrackingInterfaces.WorkItem, asOf?: Date) {
    if (!workItem.id) throw new Error("workItem had no id");
    const cacheId = this.getWorkItemCacheId(teamContext?.project, workItem.id, asOf);
    try {
      let cacheEntry = await this.get(cacheId);
      await this.put(cacheId, JSON.stringify(workItem), cacheEntry);
    } catch (error: any) {
      console.warn(error);
    }
  }

  public async getBacklog(teamContext: CoreInterfaces.TeamContext, backlogName: string) {
    const cacheId = this.getBacklogCacheId(teamContext, backlogName);
    let workItemLinks: WorkItemTrackingInterfaces.WorkItemLink[] | undefined;
    let cacheEntry = await this.get(cacheId);
    try {
      if (cacheEntry && !cacheEntry.isExpired) {
        workItemLinks = JSON.parse(cacheEntry.data ?? "") as WorkItemTrackingInterfaces.WorkItemLink[];
      }
    } catch(error: any) {
      console.warn(error);
    }
    return { workItemLinks, cacheEntry };
  }

  public async putBacklog(teamContext: CoreInterfaces.TeamContext, backlogName: string, workItemLinks: WorkItemTrackingInterfaces.WorkItemLink[]) {
    const cacheId = this.getBacklogCacheId(teamContext, backlogName);
    try {
      let cacheEntry = await this.get(cacheId);
      await this.put(cacheId, JSON.stringify(workItemLinks), cacheEntry);
    } catch (error: any) {
      console.warn(error);
    }
  }
}