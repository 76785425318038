export function batchArray<T>(arr: T[], batchSize: number): T[][] {
  const batches: T[][] = [];
  let i = 0;
  const n = arr.length;

  while (i < n) {
    let slice = arr.slice(i, i += batchSize);
    batches.push(slice);
  }

  return batches;
}

export function upsertAssignArray<T extends { id: number }>(arr: T[], element: T) {
  const index = arr.findIndex((item) => item.id === element.id);
  if (index > -1) {
    Object.assign(arr[index], element);
  } else {
    arr.push(element);
  }
}

export function camelize(str: string) {
  if (str.length <= 2) {
    return str.toLowerCase();
  }

  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

// export function searchTree (tree: any, value: any, key = 'id', reverse = false) {
//   const stack = [ tree ];
//   while (stack.length) {
//     const node = stack[reverse ? 'pop' : 'shift']();
//     if (node[key] === value) return node;
//     node.children && stack.push(...node.children);
//   }
//   return null;
// }

export function searchTree<T extends { children: T[] }> (tree: T, predicate: (node: T) => boolean, skip: undefined | ((node: T) => boolean) = undefined, reverse = false) {
  const stack = [ tree ];
  while (stack.length) {
    const node = stack[reverse ? 'pop' : 'shift']();
    if (node) {
      if(skip && skip(node)) continue;
      if (predicate(node)) return node;
      node.children && stack.push(...node.children);
    }
  }
  return null;
}

export function searchTreeAll<T extends { children: T[] }> (tree: T, predicate: (node: T) => boolean, skip: undefined | ((node: T) => boolean) = undefined, reverse = false) {
  const stack = [ tree ];
  const results: T[] = [];
  while (stack.length) {
    const node = stack[reverse ? 'pop' : 'shift']();
    if (node) {
      if(skip && skip(node)) continue;
      if (predicate(node)) results.push(node);
      node.children && stack.push(...node.children);
    }
  }
  return results;
}

export function sleep(ms: number = 0) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


// export function useImmer<T>(initialValue: T | (() => T)) {
//   const [val, updateValue] = useState<T>(() =>
//     freeze(
//       typeof initialValue === "function" ? (initialValue as () => T)() : initialValue,
//       true
//     )
//   );

//   return [
//     val,
//     // useCallback<(draft: Draft<T>) => any>(updater => {
//     //   updateValue(prevState => produce<T>(prevState, updater as any));
//       // if (typeof updater === "function") updateValue(prevState => produce(prevState, updater));
//       // else updateValue(freeze(updater));
//     function(updater: T | ((draft: WritableDraft<T>) => any)) {
//       if (typeof updater === "function") updateValue(prevState => produce(prevState, updater as (draft: WritableDraft<T>) => any));
//       else updateValue(freeze(updater));
//     }//, []),
//   ];
// }

// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
export function shadeColor(color: string, percent: number) {
  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);

  R = parseInt((R * (1 + percent)).toFixed());
  G = parseInt((G * (1 + percent)).toFixed());
  B = parseInt((B * (1 + percent)).toFixed());

  R = (R<255)?R:255;
  G = (G<255)?G:255;
  B = (B<255)?B:255;

  var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}

// https://blog.oliverjumpertz.dev/the-moving-average-simple-and-exponential-theory-math-and-implementation-in-javascript
export function simpleMovingAverage(values: number[], window = 4, n = Infinity) {
  if (!values) {
    return [];
  }
  if (values.length < window) {
    return [values.reduce((prev, curr) => prev + curr, 0) / values.length]; // Return a normal average
  }

  let index = window - 1;
  const length = values.length + 1;

  const simpleMovingAverages = [];

  let numberOfSMAsCalculated = 0;

  while (++index < length && numberOfSMAsCalculated++ < n) {
    const windowSlice = values.slice(index - window, index);
    const sum = windowSlice.reduce((prev, curr) => prev + curr, 0);
    simpleMovingAverages.push(sum / window);
  }

  return simpleMovingAverages;
}

export function exponentialMovingAverage(values: number[], window = 4) {
  if (!values) {
    return [];
  }
  if (values.length < window) {
    return [values.reduce((prev, curr) => prev + curr, 0) / values.length]; // Return a normal average
  }

  let index = window - 1;
  let previousEmaIndex = 0;
  const length = values.length;
  const smoothingFactor = 2 / (window + 1);

  const exponentialMovingAverages: number[] = [];

  const [sma] = simpleMovingAverage(values, window, 1);
  exponentialMovingAverages.push(sma);

  while (++index < length) {
    const value = values[index];
    const previousEma = exponentialMovingAverages[previousEmaIndex++];
    const currentEma = (value - previousEma) * smoothingFactor + previousEma;
    exponentialMovingAverages.push(currentEma);
  }

  return exponentialMovingAverages;
}