import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, styled, Typography } from '@mui/material';
import orange from '@mui/material/colors/orange';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Iteration from '../../model/Iteration';
import WorkItem from '../../model/WorkItem';
import WorkItemState from '../../model/WorkItemState';
import WorkItemType from '../../model/WorkItemType';
import BacklogContext from '../BacklogContext';
import WorkItemIterationsContext from '../measures/WorkItemIterationsContext';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: orange[100],
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid ' + orange[500],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: orange[500],
  },
}));

type WarningsMetricProps = {
  workItem: WorkItem,
  allNodes: WorkItem[],
  leafNodes: WorkItem[]
};

export default function WarningsMetric({workItem, allNodes, leafNodes}: WarningsMetricProps) {
  const { endIteration } = useContext(WorkItemIterationsContext);
  const { backlog } = useContext(BacklogContext);
  const [warnings, setWarnings] = useState<JSX.Element[]>([]);

  const shouldNotBeLate = useCallback((workItem: WorkItem) => {
    if (workItem.state === WorkItemState.Completed || workItem.iterationAsNumber === WorkItem.FUTURE_ITERATION) return true;
    return workItem.iterationAsNumber >= Iteration.currentIterationAsNumber;
  }, []);

  const shouldHaveOriginalEstimate = useCallback((workItem: WorkItem) => {
    if (workItem.state === WorkItemState.Completed) return true;

    return (workItem.originalEstimate && workItem.remainingDays === undefined) ||
      (workItem.originalEstimate && workItem.remainingDays && workItem.originalEstimate >= workItem.remainingDays);
  }, []);

  const shouldNotBeStarted = useCallback((workItem: WorkItem) => {
    if (workItem.type !== WorkItemType.KeyResult) return true;
    return workItem.state !== WorkItemState.Started;
  }, []);

  const shouldTakeLongerThanChildren = useCallback((workItem: WorkItem) => {
    const { end } = workItem.getScheduledIterationsAsNumber(workItem.allNodes);
    if (end === WorkItem.FUTURE_ITERATION)
      return workItem.iterationAsNumber === WorkItem.FUTURE_ITERATION ? true : false;

    return workItem.iterationAsNumber >= end;
  }, []);

  const shouldBeComplete = useCallback((workItem: WorkItem) => {
    if (!workItem.isCommitted || !workItem.isScheduled || workItem.bestKnownRemainingDays !== 0) return true;
    return workItem.state === WorkItemState.Completed;
  }, []);

  const shouldHaveTasks = useCallback((workItem: WorkItem) => {
    if (workItem.type === WorkItemType.Task || workItem.state === WorkItemState.Completed || !workItem.isScheduled) return true;
    let oneMonthOut = new Date();
    oneMonthOut.setDate(oneMonthOut.getDate() + 30);
    let iterationStart = WorkItem.getIterationAsDateRange(workItem.iterationAsNumber)?.start;
    if (!iterationStart) return true;
    return oneMonthOut < iterationStart;
  }, []);

  const shouldHaveCorrectParentType = useCallback((workItem: WorkItem) => {
    if (!workItem.parentId) return workItem.type === WorkItemType.Objective; // Only objectives are allowed no parent
    let workItemParent = backlog.findById(workItem.parentId);
    if (!workItemParent) return workItem.type === WorkItemType.Objective; // Only objectives are allowed no parent

    switch (workItem.type) {
      case WorkItemType.Deliverable:
        return workItemParent.type === WorkItemType.Scenario || workItemParent.type === WorkItemType.Feature;
      case WorkItemType.Feature:
        return workItemParent.type === WorkItemType.KeyResult;
      case WorkItemType.KeyResult:
        return workItemParent.type === WorkItemType.Objective;
      case WorkItemType.Objective:
        return workItemParent.type === WorkItemType.Objective || workItemParent.type === WorkItemType.Backlog;
      case WorkItemType.Scenario:
        return workItemParent.type === WorkItemType.KeyResult;
      case WorkItemType.Task:
        return workItemParent.type === WorkItemType.Deliverable;
    }
    return false;
  }, [backlog]);

  const shouldHaveChildrenInSameIteration = useCallback(() => {
    return allNodes.reduce((total, node) => {
      if (node.type !== WorkItemType.Deliverable) return total;

      return total + (node.children.find(child => child.iteration !== node.iteration) ? 1 : 0);
    }, 0) === 0;
  }, [allNodes]);

  useEffect(() => {
    const warnings: JSX.Element[] = [];

    // Late
    if (!shouldNotBeLate(workItem)) {
      warnings.push(<><b>Late:</b> Target iteration was {workItem.iteration} and it is now {Iteration.currentIteration}. State is {workItem.state}.</>);
    }
    let warningCount = allNodes.reduce((total, node) => {
      if (node !== workItem && !shouldNotBeLate(node)) {
        return total + 1;
      }
      return total;
    }, 0);
    if (warningCount > 0) {
      warnings.push(<><b>Late children:</b> {warningCount} children are late and still in progress</>);
    }

    // Should be complete
    if (!shouldBeComplete(workItem)) {
      warnings.push(<><b>Should be complete:</b> Item is committed and scheduled to {workItem.iteration} but has 0 remaining days and is in state {workItem.state}</>);
    }
    warningCount = allNodes.reduce((total, node) => {
      if (node !== workItem && !shouldBeComplete(node)) {
        return total + 1;
      }
      return total;
    }, 0);
    if (warningCount > 0) {
      warnings.push(<><b>Should be complete:</b> {warningCount} children are in states that look complete but they are not marked complete</>);
    }

    // Missing estimates
    if (!shouldHaveOriginalEstimate(workItem)) {
      warnings.push(<><b>Inaccurate estimate:</b> <em>Remaining Days</em> is used as an estimate because <em>Original Estimate</em> was not set or was less than the remaining days. This will cause total work to inaccurately change over time.</>);
    }
    warningCount = leafNodes.reduce((total, node) => {
      if (node !== workItem && !shouldHaveOriginalEstimate(node)) {
        return total + 1;
      }
      return total;
    }, 0);
    if (warningCount > 0) {
      warnings.push(<><b>Inaccurate estimates in {warningCount} children:</b> <em>Remaining Days</em> is used as an estimate because <em>Original Estimate</em> was not set or was less than the remaining days. This will cause total work to inaccurately change over time.</>);
    }

    // Bad key result state
    if (!shouldNotBeStarted(workItem)) {
      warnings.push(<><b>Should not be Started:</b> <em>Started</em> is not a recommended state for key results. Use <em>On Track</em> or <em>At Risk</em>.</>)
    }
    warningCount = allNodes.reduce((total, node) => {
      if (node !== workItem && !shouldNotBeStarted(node)) {
        return total + 1;
      }
      return total;
    }, 0);
    if (warningCount > 0) {
      warnings.push(<><b>{warningCount} children should not be Started:</b> <em>Started</em> is not a recommended state for key results. Use <em>On Track</em> or <em>At Risk</em>.</>);
    }

    // Should have tasks
    if (leafNodes.includes(workItem) && !shouldHaveTasks(workItem)) {
      warnings.push(<><b>Should have tasks:</b> The iteration starts within 30 days or has already started but item has no tasks.</>);
    }
    warningCount = leafNodes.reduce((total, node) => {
      if (node !== workItem && !shouldHaveTasks(node)) {
        return total + 1;
      }
      return total;
    }, 0);
    if (warningCount > 0) {
      warnings.push(<><b>{warningCount} children should have tasks:</b> The iteration starts within 30 days or has already started but items have no tasks.</>);
    }

    if (!shouldHaveCorrectParentType(workItem)) {
      warnings.push(<><b>Should have correct parent type:</b> Common mistakes are not parenting scenarios and features to key results, or tasks to deliverables.</>)
    }
    warningCount = allNodes.reduce((total, node) => {
      if (node !== workItem && !shouldHaveCorrectParentType(node)) {
        return total + 1;
      }
      return total;
    }, 0);
    if (warningCount > 0) {
      warnings.push(<><b>{warningCount} children do not have correct parent type:</b> Common mistakes are not parenting scenarios and features to key results, or tasks to deliverables.</>);
    }

    // Should take longer than children
    if (!shouldTakeLongerThanChildren(workItem)) {
      warnings.push(<><b>Should take longer:</b> Target iteration is {workItem.iteration} but not all children are scheduled before it ends.</>)
    }
    warningCount = allNodes.reduce((total, node) => {
      if (node !== workItem && !shouldTakeLongerThanChildren(node)) {
        return total + 1;
      }
      return total;
    }, 0);
    if (warningCount > 0) {
      warnings.push(<><b>{warningCount} children should take longer:</b> Not all children complete before their own children.</>);
    }

    if (!shouldHaveChildrenInSameIteration()) {
      warnings.push(<><b>Should have children in same iteration:</b> Not all child tasks are scheduled to the same iteration as thier parent deliverable.</>)
    }

    setWarnings(warnings);
  }, [workItem, endIteration, allNodes, leafNodes,
    shouldNotBeLate, shouldHaveOriginalEstimate, shouldNotBeStarted,
    shouldTakeLongerThanChildren, shouldBeComplete, shouldHaveTasks,
    shouldHaveCorrectParentType, shouldHaveChildrenInSameIteration]);

  return (
    warnings.length ?
      <HtmlTooltip arrow
        title={
          <>
            <Typography color="inherit" fontWeight="bold">Issues Detected</Typography>
            <ol>
              {warnings.map((warning, index) => <Fragment key={index}><li>{warning}</li></Fragment>)}
            </ol>
          </>
        }
      >
        <IconButton>
          <WarningIcon htmlColor='orange' />
        </IconButton>
      </HtmlTooltip>
    : <></>
  );
}