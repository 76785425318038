import { Model } from '@nozbe/watermelondb'
import {text, date} from '@nozbe/watermelondb/decorators';

export default class CacheEntry extends Model {
  public static MAX_AGE: number = 1000 * 60 * 60 * 24;

  static table = "cache_entries";

  @text("cache_id") cacheId?: string;
  @text("data") data?: string;
  @date("created_at") createdAt?: Date;
  @date("updated_at") updatedAt?: Date;

  public get isExpired() {
    let updatedAt = this.updatedAt ?? new Date();
    return Date.now() - updatedAt.valueOf() > CacheEntry.MAX_AGE;
  }
}