// import blue from "@mui/material/colors/blue";
// import green from "@mui/material/colors/green";
// import grey from "@mui/material/colors/grey";
// import red from "@mui/material/colors/red";
// import yellow from "@mui/material/colors/yellow";
import { useEffect, useState } from "react";
import WorkItem from "../../model/WorkItem";
import WorkItemMetric from "./WorkItemMetric";

type CommittedCompletedMetricProps = {
  workItem: WorkItem;
  leafNodes: WorkItem[];
};

// function getTextColor(state: WorkItemState, percentage: number) {
//   switch(state)
//   {
//     case WorkItemState.AtRisk:
//       return yellow[900];
//     case WorkItemState.Committed:
//     case WorkItemState.Completed:
//     case WorkItemState.OnTrack:
//       return green[700];
//     case WorkItemState.Cut:
//       return grey[700];
//     case WorkItemState.Missed:
//       return red[500];
//     case WorkItemState.Proposed:
//       return grey[700];
//     case WorkItemState.Started:
//     default:
//       return blue[500];
//   }
// }

// function getBarColor(state: WorkItemState, percentage: number) {
//   switch(state)
//   {
//     case WorkItemState.AtRisk:
//       return 'warning';
//     case WorkItemState.Committed:
//     case WorkItemState.Completed:
//     case WorkItemState.OnTrack:
//       return 'success';
//     case WorkItemState.Cut:
//     case WorkItemState.Proposed:
//         return 'info';
//     case WorkItemState.Missed:
//       return 'error';
//     case WorkItemState.Started:
//     default:
//       return 'primary';
//   }
// }

export default function CommittedCompletedMetric({ workItem, leafNodes }: CommittedCompletedMetricProps) {
  const [committedRemainingDays, setCommittedRemainingDays] = useState(0);
  const [committedOriginalEstimate, setCommittedOriginalEstimate] = useState(0);
  const [committedCompletedDays, setCommittedCompletedDays] = useState(0);
  const [committedCompletedPercent, setCommittedCompletedPercent] = useState(0);

  // Calculate remainingDays
  useEffect(() => {
    const total = leafNodes.reduce((total: number, item: WorkItem) => {
      if (!item.isCommitted)
        return total;

      return total + (item.remainingDays ?? item.originalEstimate ?? 0);
    }, 0);
    setCommittedRemainingDays(total);
  }, [leafNodes]);

  // Calculate originalEstimate
  useEffect(() => {
    const total = leafNodes.reduce((total: number, item: WorkItem) => {
      if (!item.isCommitted)
        return total;

      let estimate = (item.originalEstimate ?? 0);
      let remaining = (item.remainingDays ?? 0);
      estimate = remaining > estimate ? remaining : estimate;
      return total + estimate;
    }, 0);
    setCommittedOriginalEstimate(total);
  }, [leafNodes]);

  // Caclulate completedDays
  useEffect(() => {
    setCommittedCompletedDays(committedOriginalEstimate - committedRemainingDays);
  }, [committedOriginalEstimate, committedRemainingDays]);

  // Caclulate percentComplete
  useEffect(() => {
    setCommittedCompletedPercent(committedOriginalEstimate ? (100 * committedCompletedDays / committedOriginalEstimate) : 0);
  }, [committedOriginalEstimate, committedCompletedDays]);

  return (
    <WorkItemMetric
      percentage={parseInt(Number(committedCompletedPercent).toFixed())}
      state={<>FOR WORK COMMITTED</>}
      detail={<><br/>{committedRemainingDays}d of {committedOriginalEstimate ?? committedRemainingDays}d remaining</>}
      sx={{marginBottom: 2}}
      // textColor={getTextColor(workItem.state, committedCompletedPercent)}
      // barColor={getBarColor(workItem.state, committedCompletedPercent)}
      />
  );
}