import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "34297e07-3742-4172-9c95-2708fec5edc6",
    authority: "https://login.microsoftonline.com/organizations", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: "https://zealous-island-0d6c2cc1e.1.azurestaticapps.net",
    redirectUri: window.location.protocol + "//" + window.location.hostname + (((window.location.protocol === "https" && window.location.port === "443") || !window.location.port) ? "" : (":" + window.location.port)),
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"]
  // scopes: ["https://graph.microsoft.com/User.Read"]
};

// export const devOpsRequest: RedirectRequest = {
//   scopes: ["https://graph.microsoft.com/User.Read", "https://app.vssps.visualstudio.com/user_impersonation"]
//   // scopes: ["https://graph.microsoft.com/User.Read"]
// };