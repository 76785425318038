export default class Iteration {
  public static get currentIteration(): string {
    const date = new Date();
    const year = (date.getFullYear() - 2000).toString();
    let month = (date.getMonth() + 1).toString();
    month = month.length === 1 ? "0" + month : month;
    return year + month;
  }

  public static get currentIterationAsNumber(): number {
    return parseInt(Iteration.currentIteration);
  }

  public static get nextIteration(): string {
    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    const year = (date.getFullYear() - 2000).toString();
    let month = (date.getMonth() + 1).toString();
    month = month.length === 1 ? "0" + month : month;
    return year + month;
  }

  public static get nextIterationAsNumber(): number {
    return parseInt(Iteration.nextIteration);
  }
}