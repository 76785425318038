import { createContext } from 'react';
import Backlog from '../model/Backlog';
import WorkItem from '../model/WorkItem';
import WorkItemMap from '../model/WorkItemMap';

const BacklogContext = createContext({} as {
  backlog: Backlog,
  setBacklog: React.Dispatch<React.SetStateAction<Backlog>>,
  loadWorkItem: (id: number, childLevels: number, abortSignal: AbortSignal, useCache?: boolean) => Promise<WorkItem | undefined>,
  loadWorkItemMap: (id: number, childLevels: number, abortSignal: AbortSignal, useCache?: boolean, updateRelations?: boolean, asOf?: Date) => Promise<WorkItemMap | undefined>
});

export default BacklogContext;