import WorkItemIterationsContext from './WorkItemIterationsContext';
import WorkItem from '../../model/WorkItem';
import { useEffect, useState } from 'react';

type WorkItemIterationsProviderProps = {
  allNodes: WorkItem[],
  children?: React.ReactChild | React.ReactChild[];
};

export default function WorkItemIterationsProvider({ allNodes, children }: WorkItemIterationsProviderProps) {
  const [startIteration, setStartIteration] = useState("Future");
  const [endIteration, setEndIteration] = useState("Future");

  // Calculate state
  useEffect(() => {
    let iterations = allNodes.reduce((results: number[], item: WorkItem) => {
      results.push(item.iterationAsNumber);
      return results;
    }, []);
    let min = Math.min(...iterations);
    let max = Math.max(...iterations);
    if (min === Number.POSITIVE_INFINITY && (min === max || max === Number.NEGATIVE_INFINITY)) {
      setStartIteration("Future");
      setEndIteration("Future");
    } else if (min === max) {
      setStartIteration(min.toString());
      setEndIteration(min.toString());
    } else {
      setStartIteration(min === Number.POSITIVE_INFINITY ? "Future" : min.toString());
      setEndIteration(max === Number.POSITIVE_INFINITY ? "Future" : max.toString());
    }
  }, [allNodes]);

  return (
    <WorkItemIterationsContext.Provider value={{ startIteration, endIteration }}>
      {children}
    </WorkItemIterationsContext.Provider>
  );
}