enum WorkItemState {
  // Objective, Scenario, Feature, Deliverable, Task
  Committed = "Committed",
  Completed = "Completed",
  Cut = "Cut",
  Proposed = "Proposed",
  Started = "Started",

  // KeyResult
  AtRisk = "AtRisk",
  OnTrack = "OnTrack",
  Missed = "Missed"
  // Completed, Cut, Proposed, Started
}

namespace WorkItemState {
  export function toString(state: WorkItemState): string {
    switch (state) {
      case WorkItemState.AtRisk:
        return "At Risk";
      case WorkItemState.OnTrack:
        return "On Track";
      default:
        return WorkItemState[state];
    }
  }

  export function parse(state: string): WorkItemState | undefined {
    switch (state) {
      case "Committed":
        return WorkItemState.Committed;
      case "Completed":
        return WorkItemState.Completed;
      case "Cut":
        return WorkItemState.Cut;
      case "Proposed":
        return WorkItemState.Proposed;
      case "Started":
        return WorkItemState.Started;
      case "At Risk":
        return WorkItemState.AtRisk;
      case "On Track":
        return WorkItemState.OnTrack;
      case "Missed":
        return WorkItemState.Missed;
      default:
        return undefined
    }
  }

  export function isCommitted(state: WorkItemState): boolean {
    return (state !== WorkItemState.Cut && state !== WorkItemState.Proposed);
  }
}

export default WorkItemState;